import { defineStore } from "pinia";
import { supabase } from "@/service/supabase";

export const useStammdatenStore = defineStore("stammdaten", {
  state: () => ({
    data: {
      tbl_rau_klasse: [],
      tblStudents: [],
    },
    loading: {
      tbl_rau_klasse: false,
      tblStudents: false,
    },
    error: {
      tbl_rau_klasse: null,
      tblStudents: null,
    },
    subscribed: {
      tbl_rau_holzart: false,
      tbl_rau_klasse: false,
      tblStudents: false,
    },
    channels: {},
  }),
  actions: {
    async fetchData(tableName, force = false) {
      const channels = supabase.getChannels();
      const channel = channels.find((c) =>
        c.topic.includes(`realtime:public:${tableName}`)
      );

      if (this.subscribed[tableName] && channel && !force) {
        console.log(
          `Abonnement für ${tableName} aktiv, API-Aufruf übersprungen.`
        );
        return;
      }

      console.log(`Daten für ${tableName} werden geladen...`);
      this.loading[tableName] = true;

      const { data, error } = await supabase.from(tableName).select("*");
      this.data[tableName] = data ? Array.from(data) : [];
      this.error[tableName] = error ? error.message : null;
      this.loading[tableName] = false;
    },

    subscribeToChanges(tableName) {
      const channels = supabase.getChannels();
      const channel = channels.find((c) =>
        c.topic.includes(`realtime:public:${tableName}`)
      );
      if (channel) return; // Abonnement nur einmal setzen

      supabase
        .channel(`public:${tableName}`)
        .on(
          "postgres_changes",
          { event: "*", schema: "public", table: tableName },
          (payload) => {
            console.log(`Change received on ${tableName}!`, payload);
            this.fetchData(tableName, true);
          }
        )
        .subscribe((status) => {
          console.log(`Subscription status for ${tableName}:`, status);
          if (status === "SUBSCRIBED") {
            this.subscribed[tableName] = true;
          } else if (status === "ERROR") {
            console.error(`Error subscribing to changes on ${tableName}`);
            this.error[tableName] = "Subscription error";
          }
        });
    },

    unsubscribeFromChanges(tableName) {
      if (this.channels[tableName]) {
        supabase.removeChannel(this.channels[tableName]);
        this.subscribed[tableName] = false;
        delete this.channels[tableName]; // Channel entfernen
      }
    },

    unsubscribeFromAllChannels() {
      supabase.removeAllChannels();
      this.subscribed = {}; // Setzt alle subscribed-Zustände zurück
      this.subscriptionStatus = {}; // Setzt alle Status zurück
      this.channels = {}; // Leert das Channel-Objekt
      console.log("Abgemeldet von allen Channels.");
    },

    fetchQuery(tableName, query) {
      return supabase.from(tableName).select(query);
    },
  },
  getters: {
    formatRabzug(state) {
      const rows = state.data.tbl_rau_rabzug;
      const holzarten = [...new Set(rows.map((item) => item.holzart))].sort();
      const result = [];
      holzarten.forEach((ha) => {
        const children = [];
        rows.forEach((d) => {
          if (d.holzart === ha) {
            children.push({
              key: ha + d.rabzug_mittendm,
              data: {
                rabzug_mittendm: d.rabzug_mittendm,
                rabzug_cm: d.rabzug_cm,
              },
            });
          }
        });
        result.push({
          key: ha,
          data: { holzart: ha },
          children: children,
        });
      });
      return result;
    },
  },
  persist: {
    enabled: true,
  },
});
