import { createClient } from "@supabase/supabase-js";

function getCookie(name) {
  const value = `; ${document.cookie}`;
  console.log("alle Cookies:", value);
  const parts = value.split(`; ${name}=`);
  console.log(parts);
  if (parts.length === 2) return parts.pop().split(";").shift();
  return null;
}

function getSupabaseCookieName(supabaseUrl) {
  const urlParts = supabaseUrl.split(".");
  const projectRefId = urlParts[0].split("//")[1]; // Extrahieren des Projektreferenzteils
  console.log(`sb-${projectRefId}-auth-token`);
  return `sb-${projectRefId}-auth-token`;
}

// Token auslesen und dekodieren
let supabaseAuthToken = getCookie(
  getSupabaseCookieName(process.env.VUE_APP_SUPABASE_URL)
);
if (supabaseAuthToken && supabaseAuthToken.startsWith("base64-")) {
  supabaseAuthToken = supabaseAuthToken.replace("base64-", "");
  try {
    supabaseAuthToken = atob(supabaseAuthToken); // Base64 dekodieren
  } catch (e) {
    console.error("Fehler beim Decodieren des Tokens:", e);
  }
}

// Supabase-Client initialisieren
export const supabase = createClient(
  process.env.VUE_APP_SUPABASE_URL,
  process.env.VUE_APP_SUPABASE_KEY
);

// Session setzen, falls ein Token vorhanden ist
if (supabaseAuthToken) {
  try {
    var token = JSON.parse(supabaseAuthToken);
    console.log(token);
    supabase.auth.setSession({
      access_token: token.access_token,
      refresh_token: token.refresh_token,
    });
  } catch (error) {
    console.error("Fehler beim Setzen der Sitzung:", error);
  }
} else {
  console.log("Kein Authentifizierungstoken gefunden.");
}
